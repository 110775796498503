@import "~src/assets/common";
@import "~src/assets/common-assets/less/user";
@import "~src/assets/common-assets/less/linking";
@import "~src/assets/common-assets/less/ckeditor";

#filters_switcher_toggle {
  top: 400px !important;
}

#info_switcher_toggle {
  top: 250px !important;
}

@media (max-width: @breakpoint-small-max) {
  #filters_switcher_toggle {
    top: unset !important;
    bottom: 10vh;
  }

  #info_switcher_toggle {
    display: none;
  }
}

/* Quick fix for svgs with a class that makes their opacity: 0.5*/
svg .a {
  opacity: 1 !important;
}

// Engagement & Training
.events {

  @training-color: #A8B9FF;
  @webinar-color: #E0C44F;
  @engagement-color: #dab6ff;

  .card {
    height: 325px;
    background-repeat: no-repeat;
    background-position: center;

    &.webinar {
      background-image: url("~/src/assets/irish-assets/resources/resources-green.png");
    }

    &.training {
      background-image: url("~/src/assets/irish-assets/resources/resources-pink-blue.png");
    }

    &.engagement {
      background-image: url("~/src/assets/irish-assets/resources/resources-dark-blue.jpg");
    }

    .info {
      height: 50%;
      .set-blur-background(@dark-color, 50%, brightness(0.5) blur(20px));

      a {
        display: none;
      }
    }

    &:hover {
      .info {
        height: 100%;
    
        a {
          display: inline-block;
        }
      }
    }
  }

  @media (max-width: @breakpoint-small-max) {
    .card {
      height: unset;
    
      .info {
        height: 100%;
    
        a {
          display: inline-block;
        }
      }
    }
  }

  .uk-label.training {
    background-color: @training-color;
    border-color: @training-color;
  }

  .uk-label.webinar {
    background-color: @webinar-color;
    border-color: @webinar-color;
  }

  .uk-label.engagement {
    background-color: @engagement-color;
    border-color: @engagement-color;
  }

  .uk-label.funder {
    background-color: @funder-color;
    border-color: @funder-color;
    color: @global-inverse-color;
  }

  .uk-label.organization {
    background-color: @organization-color;
    border-color: @organization-color;
    color: @global-inverse-color;
  }
}
