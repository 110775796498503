@form-padding-horizontal: 25px;
@form-radio-margin-top: -2px;
@form-background: @global-inverse-color;
@form-placeholder-color: @global-meta-color;
@form-border-radius: @global-border-radius;
@form-box-shadow: @global-inset-shadow;

/** Radio - Checkbox*/
@form-radio-background: @global-inverse-color;
@form-radio-focus-background: @global-inverse-color;
@form-radio-checked-background: @global-secondary-background;
@form-radio-checked-focus-background: @global-secondary-background;
@form-radio-border: @global-muted-color;
@form-radio-border-width: @global-border-width;
@form-radio-border-radius: @global-small-border-radius;

.hook-form() {
  transition: .2s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
  box-shadow: @form-box-shadow;
  border-radius: @form-border-radius;
}

.hook-form-radio() {
  border-radius: @form-radio-border-radius;
  border: @form-radio-border-width solid @form-radio-border;
}

.hook-form-radio-checked() {
  background-size: contain;
}

.hook-form-misc() {
  button, input[type="button"], input[type="checkbox"],
  input[type="radio"], input[type="reset"], input[type="submit"],
  label, select {
    cursor: pointer;
  }

  button, input, select, textarea {
    line-height: inherit;
    font-size: 100%;
    vertical-align: middle;
  }

  input[type="checkbox"] {
    &:extend(.uk-checkbox);
    &:focus:extend(.uk-checkbox:focus) {}
    &:checked:extend(.uk-checkbox:checked) {}
  }

  input[type="radio"] {
    &:extend(.uk-radio);
    &:focus:extend(.uk-radio:focus) {}
    &:checked:extend(.uk-radio:checked) {}
  }

  [style*="visibility: hidden"] .input-wrapper {
    opacity: 0;
  }

  @import (multiple) 'input';
}

/** Inverse */
.hook-inverse-misc() {
  @import (multiple) 'input';
  @input-mode: @inverse-global-color-mode;
}
