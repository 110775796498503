@font-media-url: e("../media/fonts/aileron/");
@font-media-icon-url: e("../media/fonts/material-icons/");

/* Aileron */

@font-face {  font-family: "aileron";  font-style: italic;  font-weight: 100;   src: url("@{font-media-url}aileron-thinitalic.eot");  src: url("@{font-media-url}aileron-thinitalic.eot?#iefix") format('embedded-opentype'), url("@{font-media-url}aileron-thinitalic.woff2") format('woff2'), url("@{font-media-url}aileron-thinitalic.woff") format('woff'), url("@{font-media-url}aileron-thinitalic.ttf") format('truetype');  font-display: swap}
@font-face {  font-family: "aileron";  font-style: normal;  font-weight: 100;   src: url("@{font-media-url}aileron-thin.eot");  src: url("@{font-media-url}aileron-thin.eot?#iefix") format('embedded-opentype'), url("@{font-media-url}aileron-thin.woff2") format('woff2'), url("@{font-media-url}aileron-thin.woff") format('woff'), url("@{font-media-url}aileron-thin.ttf") format('truetype');  font-display: swap}
@font-face {  font-family: "aileron";  font-style: italic;  font-weight: 300;   src: url("@{font-media-url}aileron-lightitalic.eot");  src: url("@{font-media-url}aileron-lightitalic.eot?#iefix") format('embedded-opentype'), url("@{font-media-url}aileron-lightitalic.woff2") format('woff2'), url("@{font-media-url}aileron-lightitalic.woff") format('woff'), url("@{font-media-url}aileron-lightitalic.ttf") format('truetype');  font-display: swap}
@font-face {  font-family: "aileron";  font-style: normal;  font-weight: 300;   src: url("@{font-media-url}aileron-light.eot");  src: url("@{font-media-url}aileron-light.eot?#iefix") format('embedded-opentype'), url("@{font-media-url}aileron-light.woff2") format('woff2'), url("@{font-media-url}aileron-light.woff") format('woff'), url("@{font-media-url}aileron-light.ttf") format('truetype');  font-display: swap}
@font-face {  font-family: "aileron";  font-style: italic;  font-weight: 400;   src: url("@{font-media-url}aileron-italic.eot");  src: url("@{font-media-url}aileron-italic.eot?#iefix") format('embedded-opentype'), url("@{font-media-url}aileron-italic.woff2") format('woff2'), url("@{font-media-url}aileron-italic.woff") format('woff'), url("@{font-media-url}aileron-italic.ttf") format('truetype');  font-display: swap}
@font-face {  font-family: "aileron";  font-style: normal;  font-weight: 400;   src: url("@{font-media-url}aileron-regular.eot");  src: url("@{font-media-url}aileron-regular.eot?#iefix") format('embedded-opentype'), url("@{font-media-url}aileron-regular.woff2") format('woff2'), url("@{font-media-url}aileron-regular.woff") format('woff'), url("@{font-media-url}aileron-regular.ttf") format('truetype');  font-display: swap}
@font-face {  font-family: "aileron";  font-style: italic;  font-weight: 600;   src: url("@{font-media-url}aileron-semibolditalic.eot");  src: url("@{font-media-url}aileron-semibolditalic.eot?#iefix") format('embedded-opentype'), url("@{font-media-url}aileron-semibolditalic.woff2") format('woff2'), url("@{font-media-url}aileron-semibolditalic.woff") format('woff'), url("@{font-media-url}aileron-semibolditalic.ttf") format('truetype');  font-display: swap}
@font-face {  font-family: "aileron";  font-style: normal;  font-weight: 600;   src: url("@{font-media-url}aileron-semibold.eot");  src: url("@{font-media-url}aileron-semibold.eot?#iefix") format('embedded-opentype'), url("@{font-media-url}aileron-semibold.woff2") format('woff2'), url("@{font-media-url}aileron-semibold.woff") format('woff'), url("@{font-media-url}aileron-semibold.ttf") format('truetype');  font-display: swap}
@font-face {  font-family: "aileron";  font-style: italic;  font-weight: 700;   src: url("@{font-media-url}aileron-bolditalic.eot");  src: url("@{font-media-url}aileron-bolditalic.eot?#iefix") format('embedded-opentype'), url("@{font-media-url}aileron-bolditalic.woff2") format('woff2'), url("@{font-media-url}aileron-bolditalic.woff") format('woff'), url("@{font-media-url}aileron-bolditalic.ttf") format('truetype');  font-display: swap}
@font-face {  font-family: "aileron";  font-style: normal;  font-weight: 700;   src: url("@{font-media-url}aileron-bold.eot");  src: url("@{font-media-url}aileron-bold.eot?#iefix") format('embedded-opentype'), url("@{font-media-url}aileron-bold.woff2") format('woff2'), url("@{font-media-url}aileron-bold.woff") format('woff'), url("@{font-media-url}aileron-bold.ttf") format('truetype');  font-display: swap}
@font-face {  font-family: "aileron";  font-style: italic;  font-weight: 900;   src: url("@{font-media-url}aileron-blackitalic.eot");  src: url("@{font-media-url}aileron-blackitalic.eot?#iefix") format('embedded-opentype'), url("@{font-media-url}aileron-blackitalic.woff2") format('woff2'), url("@{font-media-url}aileron-blackitalic.woff") format('woff'), url("@{font-media-url}aileron-blackitalic.ttf") format('truetype');  font-display: swap}
@font-face {  font-family: "aileron";  font-style: normal;  font-weight: 900;   src: url("@{font-media-url}aileron-black.eot");  src: url("@{font-media-url}aileron-black.eot?#iefix") format('embedded-opentype'), url("@{font-media-url}aileron-black.woff2") format('woff2'), url("@{font-media-url}aileron-black.woff") format('woff'), url("@{font-media-url}aileron-black.ttf") format('truetype');  font-display: swap}

/* Material Icons */

@font-face {  font-family: 'Material Icons';  font-style: normal;  font-weight: 400;
  src: url("@{font-media-icon-url}MaterialIcons-Regular.eot");
  src: local('Material Icons'), local('MaterialIcons-Regular'),
  url("@{font-media-icon-url}MaterialIcons-Regular.woff2") format('woff2'),
  url("@{font-media-icon-url}MaterialIcons-Regular.woff") format('woff'),
  url("@{font-media-icon-url}MaterialIcons-Regular.ttf") format('truetype');
  font-display: swap;
}

@font-face {  font-family: 'Material Icons Outlined';  font-style: normal;  font-weight: 400;
  src: url("@{font-media-icon-url}MaterialIconsOutlined-Regular.eot");
  src: local('Material Icons Outlined'), local('MaterialIconsOutlined-Regular'),
  url("@{font-media-icon-url}MaterialIconsOutlined-Regular.woff2") format('woff2'),
  url("@{font-media-icon-url}MaterialIconsOutlined-Regular.woff") format('woff'),
  url("@{font-media-icon-url}MaterialIconsOutlined-Regular.ttf") format('truetype');
  font-display: swap;
}

@font-face {  font-family: 'Material Icons Round';  font-style: normal;  font-weight: 400;
  src: url("@{font-media-icon-url}MaterialIconsRound-Regular.eot");
  src: local('Material Icons'), local('MaterialIconsRound-Regular'),
  url("@{font-media-icon-url}MaterialIconsRound-Regular.woff2") format('woff2'),
  url("@{font-media-icon-url}MaterialIconsRound-Regular.woff") format('woff'),
  url("@{font-media-icon-url}MaterialIconsRound-Regular.ttf") format('truetype');
  font-display: swap;
}

@font-face {  font-family: 'Material Icons Sharp';  font-style: normal;  font-weight: 400;
  src: url("@{font-media-icon-url}MaterialIconsSharp-Regular.eot");
  src: local('Material Icons Sharp'), local('MaterialIconsSharp-Regular'),
  url("@{font-media-icon-url}MaterialIconsSharp-Regular.woff2") format('woff2'),
  url("@{font-media-icon-url}MaterialIconsSharp-Regular.woff") format('woff'),
  url("@{font-media-icon-url}MaterialIconsSharp-Regular.ttf") format('truetype');
  font-display: swap;
}

@font-face {  font-family: 'Material Icons Two Tone';  font-style: normal;  font-weight: 400;
  src: url("@{font-media-icon-url}MaterialIconsTwoTone-Regular.eot");
  src: local('Material Icons Two Tone'), local('MaterialIconsTwoTone-Regular'),
  url("@{font-media-icon-url}MaterialIconsTwoTone-Regular.woff2") format('woff2'),
  url("@{font-media-icon-url}MaterialIconsTwoTone-Regular.woff") format('woff'),
  url("@{font-media-icon-url}MaterialIconsTwoTone-Regular.ttf") format('truetype');
  font-display: swap;
}
