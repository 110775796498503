/** Muted link*/
@link-muted-hover-color: @global-link-hover-color;

/** Text Link */
@link-text-hover-color: @global-link-hover-color;

/** Heading Link */
@link-heading-hover-color: @global-link-hover-color;
@link-heading-hover-text-decoration: underline;

.hook-link-heading() {
  color: @global-primary-background;
}
