@section-container-background: @global-inverse-color;
@section-container-border: @global-border-width solid @global-border;
@section-container-margin-top: @global-font-size + 2*@global-xsmall-gutter;
@section-tab-padding: @global-xsmall-gutter @global-gutter;
@section-tab-background: @muted-color;
@section-tab-border: @section-container-border;
@section-tab-color: @placeholder-color;
@section-tab-active-color: @global-primary-background;
@section-tab-active-background: @section-container-background;
@section-tab-width: @width-small-width;

&.semiFiltered {
  &, & > * > .highcharts-series-group {
    opacity: 0.5;
  }
}

.number-preview {
  border: @global-border-width solid @global-border;
  background: transparent;
  border-radius: @global-border-radius;
  min-width: 100px;
  min-height: 70px;
}

.refresh-indicator {
  background-color: @global-overlay-background;
  border-radius: @global-border-radius;
  position: absolute;
  color: @global-inverse-color;
  z-index: 1;
}

.section {
  padding: 60px 45px;
  border-radius: @global-border-radius;
  border: @global-border-width solid @global-border;
  position: relative;
  background: @global-inverse-color;
  border-left: 5px @global-primary-background solid;

  & > .tools {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 5px 15px;
    background-image: @global-primary-gradient;
    color: @global-inverse-color;
    -webkit-clip-path: polygon(20% 5%, 80% 5%, 100% 100%, 0% 100%);
    clip-path: polygon(20% 5%, 80% 5%, 100% 100%, 0% 100%);
    display: none;
  }

  &:hover {
    & > .tools {
      display: block;

      a {
        color: currentColor;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.indicator-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: @global-overlay-background;
  width: 100%;
  height: 100%;

  .inner {
    background: @global-background;
    border-radius: @global-border-radius;
    width: 75%;
    max-height: 75%;
    overflow: auto;
  }
}

.section-container:extend(.uk-padding) {
  background: @section-container-background;
  border: @section-container-border;
  position: relative;
  margin-top: @section-container-margin-top + @global-large-margin;

  ul.tabs {
    position: absolute;
    top: -@section-container-margin-top - @global-border-width;
    left: 0;
    display: flex;
    list-style: none;
    padding-left: 0;
    font-size: @global-small-font-size;

    & > li:extend(.clickable) {
      padding: @section-tab-padding;
      background: @section-tab-background;
      color: @section-tab-color;
      border: @section-tab-border;
      border-bottom: none;
      height: @global-font-size;
      line-height: @global-font-size;
      margin-left: -@global-border-width;
      width: @section-tab-width;

      &:first-child {
        border-top-left-radius: @global-small-border-radius;
      }

      &:last-child {
        border-top-right-radius: @global-small-border-radius;
      }

      &.uk-active:extend(.uk-text-bold) {
        background: @section-tab-active-background;
        border-bottom: @section-tab-active-background;
        color: @section-tab-active-color;
      }
    }
  }
}
