@notification-message-font-size: @global-font-size;
@notification-message-line-height: @global-line-height;
@notification-message-border-width: @global-border-width;
@notification-message-border-radius: @global-border-radius;
@notification-message-box-shadow: @global-default-shadow;

@notification-message-background: @global-background;
@notification-message-color: @global-color;
@notification-message-border: @global-border;

/* Primary */
@notification-message-primary-color: @info-color;
@notification-message-primary-background: @info-background-color;
@notification-message-primary-border: @info-border-color;

/* Success */
@notification-message-success-background: @success-background-color;
@notification-message-success-border: @success-border-color;

/* Warning */
@notification-message-warning-background: @warning-background-color;
@notification-message-warning-border: @warning-border-color;

/* Danger */
@notification-message-danger-background: @danger-background-color;
@notification-message-danger-border: @danger-border-color;

.hook-notification-message() {
  border: @notification-message-border-width solid @notification-message-border;
  border-radius: @notification-message-border-radius;
  box-shadow: @notification-message-box-shadow;
  word-break: break-all;
}

.hook-notification-message-primary() {
  background: @notification-message-primary-background;
  border-color: @notification-message-primary-border;
}

.hook-notification-message-success() {
  background: @notification-message-success-background;
  border-color: @notification-message-success-border;
}

.hook-notification-message-warning() {
  background: @notification-message-warning-background;
  border-color: @notification-message-warning-border;
}

.hook-notification-message-danger() {
  background: @notification-message-danger-background;
  border-color: @notification-message-danger-border;
}
