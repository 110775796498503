@pagination-margin-horizontal: 15px;
@pagination-item-border-radius: 326px;
@pagination-item-color: @global-color;
@pagination-item-font-size: @global-small-font-size;
@pagination-item-line-height: 25px;
@pagination-item-min-width: 35px;
@pagination-item-next-previous-color: @global-primary-background;
@pagination-item-padding-horizontal: 5px;
@pagination-item-padding-vertical: 5px;

/** Hover */
@pagination-item-hover-color: @global-primary-background;

/** Active */
@pagination-item-active-background: @global-primary-background;
@pagination-item-active-color: @global-inverse-color;

.hook-pagination-item() {
  min-width: @pagination-item-min-width;
  line-height: @pagination-item-line-height;
  box-sizing: border-box;
  text-align: center;
  transition: .1s ease-in-out;
  transition-property: color,background-color,border-color,box-shadow;
  background-color: transparent;
  font-size: @pagination-item-font-size;
  border-radius: @pagination-item-border-radius;
}

.hook-pagination-item-active() {
  background-color: @pagination-item-active-background;
}

.hook-pagination-misc() {

  .uk-pagination-next, .uk-pagination-previous {
    color: @pagination-item-next-previous-color;
  }
}
