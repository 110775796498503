@height-xsmall-height: 80px;
@height-small-height: 160px;
@height-medium-height: 320px;
@height-large-height: 480px;

.hook-height-misc() {
  .uk-height-xsmall {
    height: @height-xsmall-height;
  }

  .uk-height-max-xsmall {
    max-height: @height-xsmall-height;
  }

  .uk-height-min-xsmall {
    min-height: @height-xsmall-height;
  }

  .uk-height-min-small {
    min-height: @height-small-height;
  }

  .uk-height-min-medium {
    min-height: @height-medium-height;
  }

  .uk-height-min-large {
    min-height: @height-large-height;
  }
}
