@linking-add-button-background: @global-inverse-color;
@linking-add-button-color: @global-primary-background;
@linking-add-button-border-width: @global-border-width;
@linking-add-button-border: @global-primary-background;
@linking-add-button-hover-background: @global-secondary-background;
@linking-add-button-hover-color: @global-inverse-color;
@linking-add-button-hover-border: @global-secondary-background;

@linking-selected-button-background: @global-secondary-background;
@linking-selected-button-color: @global-inverse-color;
@linking-selected-button-border-width: @global-border-width;
@linking-selected-button-border: @global-secondary-background;
@linking-selected-button-hover-background: @global-inverse-color;
@linking-selected-button-hover-color: @global-secondary-background;
@linking-selected-button-hover-border: @global-secondary-background;

.linking {

  .linking-add-button:extend(.uk-box-no-shadow):extend(.uk-icon-button) {
    background-color: @linking-add-button-background;
    color: @linking-add-button-color !important;
    border: @linking-add-button-border-width solid @linking-add-button-border;

    &:hover {
      background-color: @linking-add-button-hover-background;
      color: @linking-add-button-hover-color !important;
      border-color: @linking-add-button-hover-border;
    }
  }

  .linking-selected-button:extend(.uk-box-no-shadow):extend(.uk-icon-button) {
    background-color: @linking-selected-button-background;
    color: @linking-selected-button-color !important;
    border: @linking-selected-button-border-width solid @linking-selected-button-border;

    &:hover {
      background-color: @linking-selected-button-hover-background;
      color: @linking-selected-button-hover-color !important;
      border-color: @linking-selected-button-hover-border;
    }
  }
}
