@import "~src/assets/extend-theme/less/_import";
@import "~src/assets/common-assets/less/general";
@import "~src/assets/common-assets/less/landing";
@import "~src/assets/common-assets/less/dashboard";
@import "~src/assets/common-assets/less/indicators";

@general-search-form-background: none;

.hook-general-search-form() {
  & #searchForm {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
}

& #filters_icon .start {
  stop-color: @global-primary-background;
}

& #filters_icon .end {
  stop-color: @global-secondary-background;
}
