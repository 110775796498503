/* Secondary */
@label-secondary-background: @irish-global-secondary-color;
@label-secondary-color: @global-inverse-color;
@label-secondary-border: @irish-global-secondary-color;

/* Search filter */
@label-search-filter-background: @irish-global-secondary-color;
@label-search-filter-color: @global-inverse-color;
@label-search-filter-border: @irish-global-secondary-color;

.hook-label-misc() {
  .uk-label-secondary {
    & .uk-close {
      color: inherit !important;
    }
  }
}
