.hook-container-misc() {
  @media (max-width: @container-xlarge-max-width) {
    .uk-container-large {
      max-width: @container-max-width;
    }

    .uk-container-xlarge {
      max-width: @container-large-max-width;
    }
  }
}
