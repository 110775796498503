@width-xsmall-width: 100px;

.hook-width-misc() {
  .uk-width-xsmall {
    width: @width-xsmall-width;
  }

  @media (min-width: @breakpoint-small) {
    .uk-width-xsmall\@s {
      width: @width-xsmall-width;
    }
  }

  @media (min-width: @breakpoint-medium) {
    .uk-width-xsmall\@m {
      width: @width-xsmall-width;
    }
  }

  @media (min-width: @breakpoint-large) {
    .uk-width-xsmall\@l {
      width: @width-xsmall-width;
    }
  }

  @media (min-width: @breakpoint-xlarge) {
    .uk-width-xsmall\@xl {
      width: @width-xsmall-width;
    }
  }
}
