@heading-font-weight: 700;
@heading-line-height: 1.25;

@heading-small-font-size-l: 74px;
@heading-small-font-size: 41px;
@heading-small-font-weight: @heading-font-weight;
@heading-small-line-height: @heading-line-height;

@heading-medium-font-size-l: 83px;
@heading-medium-font-size: 52px;
@heading-medium-font-weight: @heading-font-weight;
@heading-medium-line-height: @heading-line-height;

@heading-large-font-size-l: 94px;
@heading-large-font-size: 52px;
@heading-large-font-weight: @heading-font-weight;
@heading-large-line-height: @heading-line-height;

@heading-xlarge-font-size-l: 119px;
@heading-xlarge-font-size: 52px;
@heading-xlarge-font-weight: @heading-font-weight;
@heading-xlarge-line-height: @heading-line-height;

@heading-2xlarge-font-size-l: 133px;
@heading-2xlarge-font-size: 52px;
@heading-2xlarge-font-weight: @heading-font-weight;
@heading-2xlarge-line-height: @heading-line-height;

.hook-heading-small() {
  font-weight: @heading-small-font-weight;
}

.hook-heading-medium() {
  font-weight: @heading-medium-font-weight;
}

.hook-heading-large() {
  font-weight: @heading-large-font-weight;
}

.hook-heading-xlarge() {
  font-weight: @heading-xlarge-font-weight;
}

.hook-heading-2xlarge() {
  font-weight: @heading-2xlarge-font-weight;
}
