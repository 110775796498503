@card-border-radius: @global-small-border-radius;
@card-box-shadow-m: @global-default-shadow;
@card-hover-box-shadow: @global-default-shadow;

@card-default-border: @light-border;
@card-default-border-width-m: @card-default-border-width;
@card-default-hover-background: @card-hover-background;
@card-default-hover-border: @global-primary-background;


.hook-card-default() {
  .set-blur-background(@card-default-background, 80%) !important;

  .uk-slider-arrow, .uk-slider-arrow > .uk-icon-button {
    background: @card-default-background !important;
  }
}
